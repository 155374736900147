import React, { useState, useEffect, useRef, useContext } from "react";
import totaldisticon from "./assets/people 1.png";
import activetotaldisticon from "./assets/ActiveDist.png";
import totalcust from "./assets/TotalDist.png";
import refresh from "./assets/refresh-svgrepo-com.png";
import approved from "./assets/approved-shield 1.png";
import deliveriesdone from "./assets/truck-svgrepo-com.png";
import rupeeDone from "./assets/rupee-svgrepo-com (1).png";
import rupee from "./assets/rupee-svgrepo-com.png";
import tickdone from "./assets/tick-circle-svgrepo-com.png";
import pending from "./assets/pending 1.png";
import "./AdminConsole.scss";
import axios from "axios";
// import { Bar } from 'react-chartjs-2';
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { GDSRecords } from "../ENDE";
import Calendar from "react-calendar";
import Cookies from "js-cookie";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

const AdminDashboard = () => {
  let today = new Date();
  const [loading, setloading] = useState(true);
  var TotalLoans;
  var TotalCustomers;
  var LoansApproved;
  var LoansPending;
  var DeliveryDone;
  var LoansPaid;
  var Disbursed;
  var DisbursementPending;
  const [startDate, setStartDate] = useState(new Date("2020-06-30"));
  const [endDate, setEndDate] = useState(new Date());
  const [isEndDateVisible, setIsEndDateVisible] = useState(false);
  const [isStartDateVisible, setIsStartDateVisible] = useState(false);
  const options = { day: "numeric", month: "numeric", year: "2-digit" };
  const [stats, setstats] = useState([]);
  const onStartDateChange = (value) => {
    // alert(new Date(value))
    console.log(startDate.toISOString().split("T")[0]);
    setStartDate(value);
    setIsStartDateVisible(!isStartDateVisible);
    // fetchdata()
  };
  const onEndDateChange = (value) => {
    setEndDate(value);
    setIsEndDateVisible(!isEndDateVisible);
    // fetchdata()
  };
  let date =
    today.getDate() +
    "/" +
    parseInt(today.getMonth() + 1) +
    "/" +
    today.getFullYear();

  useEffect(() => {
    fetchdata();
  }, [startDate, endDate]);
  const fetchdata = async () => {
    try {
      setloading(true);
      await axios({
        url: `${process.env.REACT_APP_API_LINK}/GreattrStats/`,
        method: "GET",
        responseType: "json",
        headers: {
          startDate: new Date(
            startDate.getTime() - startDate.getTimezoneOffset() * 60000
          )
            .toISOString()
            .substring(0, 10),
          endDate: new Date(
            endDate.getTime() - endDate.getTimezoneOffset() * 60000
          )
            .toISOString()
            .substring(0, 10),
          userMobile: Cookies.get("userMobile"),
        },
      }).then((response) => {
        console.log("refilldata", response);
        // [[{"Total_loans":978}],[{"Total_Customers":1236}],[{"Loans_approved":837}],[{"Loans_pending":139}]
        // ,[{"Delivery_done":557}],[{"Loan_Paid":0}],[{"Disbursed":0}],[{"DisbursementPending":851}]]
        setstats(response.data);
        setloading(false);
        TotalLoans = response.data[0][0]["Total_loans"];
        TotalCustomers = response.data[1][0]["Total_Customers"];
        console.log(TotalCustomers);
        // LoansApproved = response.data[2][0]["Loans_approved"];
        // LoansPending = response.data[3][0]["Loans_pending"];
        // DeliveryDone = response.data[4][0]["Delivery_done"];
        // LoansPaid = response.data[5][0]["Loan_Paid"];
        // Disbursed = response.data[6][0]["Disbursed"];
        // DisbursementPending = response.data[7][0]["DisbursementPending"];
      });
    } catch (err) {
      console.log("error fetching ");
      console.log(err);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    fetchdata();
  }, []);
  if (!loading) {
    return (
      <>
        <div className="dashboard">
          
          <div className="dashboardcontent">
          <div style = {{ paddingLeft : 30}}>
                    <h1 className='align-self-end' style={{ margin: 0, marginTop: '0rem' }}>Dashboard</h1>
                    <h1 className='align-self-end' style={{ fontSize: '1.2rem', margin: 0 }}>{Cookies.get("SortString")} </h1>
                    <br />
                </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between", padding: '2%'
              }}
            >
              <div className="row col-lg-6">
                <div
                  className="col-lg-6 col-sm-12 my-1 "
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className="mx-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p className="m-0">Start Date: </p>
                    <Button
                      variant="light border-primary mx-1"
                      onClick={() => {
                        setIsStartDateVisible(!isStartDateVisible);
                      }}
                    >
                      {new Date(
                        startDate.getTime() -
                        startDate.getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)}
                    </Button>
                  </div>
                  {isStartDateVisible ? (
                    <Calendar
                      onChange={onStartDateChange}
                      className="m-2"
                      value={startDate}
                    />
                  ) : null}
                </div>
                <div
                  className="col-lg-6 col-sm-12 my-1 "
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    className="mx-1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <p className="m-0">End Date: </p>
                    <Button
                      variant="light border-primary mx-1"
                      onClick={() => {
                        setIsEndDateVisible(!isEndDateVisible);
                      }}
                    >
                      {new Date(
                        endDate.getTime() - endDate.getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substring(0, 10)}
                    </Button>
                  </div>
                  {isEndDateVisible ? (
                    <Calendar
                      onChange={onEndDateChange}
                      className="m-2"
                      value={endDate}
                    />
                  ) : null}
                </div>
                
              </div>
              <Button onClick={() => fetchdata()}>Refresh</Button>
            </div>
            

            <div className="dashboardinner">
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#fff9e1",
                }}
                onClick={() => {
                  navigate("/distributor");
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#fff9e1",
                  }}
                >
                  <img
                    src={totalcust}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div
                  className="right"
                  style={{
                    backgroundColor: "#fff9e1",
                  }}
                >
                  <h5>Total Distributors Onboarded</h5>
                  <h2>{stats[2][0]["Total_Distributors"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#d4f4e7",
                }}
                onClick={() => {
                  navigate("/distributor");
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={activetotaldisticon}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Active Distributors</h5>
                  <h2>{stats[3][0]["Total_Active_Distributors"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#9fc4f8",
                  backgroundColor: "#9fc4f8",
                }}
                onClick={() => {
                  navigate("/customer", {
                    state: {
                      Status: ["approved", "pending", "rejected"],
                      DeliveryStatus: ["Yes", "No", "true", "false"],
                      LDStatus: ["approved", "pending", "rejected"],
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#9fc4f8",
                    backgroundColor: "#9fc4f8",
                  }}
                >
                  <img
                    src={totaldisticon}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Total Loans</h5>
                  <h2>{stats[0][0]["Total_loans"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#d4f4e7",
                }}
                onClick={() => {
                  navigate("/customer", {
                    state: {
                      Status: ["approved"],
                      DeliveryStatus: ["Yes", "No", "true", "false"],
                      LDStatus: ["approved", "pending", "rejected"],
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={approved}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Approved Loans</h5>
                  <h2>{stats[4][0]["Loans_approved"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#ffd9e4",
                }}
                onClick={() => {
                  navigate("/customer", {
                    state: {
                      Status: ["pending"],
                      DeliveryStatus: ["Yes", "No", "true", "false"],
                      LDStatus: ["approved", "pending", "rejected"],
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#ffd9e4",
                  }}
                >
                  <img
                    src={pending}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Pending Loans</h5>
                  <h2>{stats[5][0]["Loans_pending"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#ffd9e4",
                }}
                onClick={() => {
                  navigate("/customer", {
                    state: {
                      Status: ["rejected"],
                      DeliveryStatus: ["Yes", "No", "true", "false"],
                      LDStatus: ["approved", "pending", "rejected"],
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#ffd9e4",
                  }}
                >
                  <img
                    src={pending}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Rejected Loans</h5>
                  <h2>{stats[10][0]["Loans_rejected"] + ""}</h2>
                </div>
              </div>

              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#d4f4e7",
                }}
                onClick={() => {
                  navigate("/customer", {
                    state: {
                      Status: ["approved", "pending", "rejected"],
                      DeliveryStatus: ["Yes", "true"],
                      LDStatus: ["approved", "pending", "rejected"],
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={deliveriesdone}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Deliveries done</h5>
                  <h2>{stats[6][0]["Delivery_done"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#9fc4f8",
                  backgroundColor: "#9fc4f8",
                }}
                onClick={() => {
                  navigate("/customer-Overdues", {
                    state: {
                      paidstatus: {
                        true: true,
                        false: false,
                      },
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#9fc4f8",
                    backgroundColor: "#9fc4f8",
                  }}
                >
                  <img
                    src={tickdone}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>EMIs Paid</h5>
                  <h2>{stats[7][0]["Loan_Paid"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#d4f4e7",
                }}
                onClick={() => {
                  navigate("/customer", {
                    state: {
                      Status: ["approved", "pending", "rejected"],
                      DeliveryStatus: ["Yes", "No", "true", "false"],
                      LDStatus: ["approved"],
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#d4f4e7",
                  }}
                >
                  <img
                    src={rupeeDone}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Disbursed</h5>
                  <h2>{stats[8][0]["Disbursed"] + ""}</h2>
                </div>
              </div>
              <div
                className="Dashboardcard"
                style={{
                  backgroundColor: "#fff9e1",
                }}
                onClick={() => {
                  navigate("/customer", {
                    state: {
                      Status: ["approved", "pending", "rejected"],
                      DeliveryStatus: ["Yes", "No", "true", "false"],
                      LDStatus: ["pending", "rejected"],
                    },
                  });
                }}
              >
                <div
                  className="left"
                  style={{
                    backgroundColor: "#fff9e1",
                  }}
                >
                  <img
                    src={rupee}
                    alt=""
                    style={{
                      width: "70%",
                    }}
                  />
                </div>
                <div className="right">
                  <h5>Disbursement Pending</h5>
                  <h2>{stats[9][0]["DisbursementPending"] + ""}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <h3>Loading</h3>
      </>
    );
  }
};

export default AdminDashboard;
