
const CryptoJS = require('crypto-js');

function convertToHash2(plaintext, key) {
    // let ciphertext = CryptoJS.AES.encrypt(plaintext, key).toString();
    let ciphertext = CryptoJS.AES.encrypt(plaintext, CryptoJS.SHA256(key), { mode: CryptoJS.mode.ECB }).toString();
    // console.log(ciphertext);
    return ciphertext;
}
function convertToText2(encrypted, key) {
    try {
        var decrypted = CryptoJS.AES.decrypt(encrypted.toString(), CryptoJS.SHA256(key), { mode: CryptoJS.mode.ECB });
        return decrypted.toString(CryptoJS.enc.Utf8)
    } catch (error) {
        console.log(error);
    }

}

function convertToText3(encrypted, key) {
    try {
        var decrypted = CryptoJS.AES.decrypt(encrypted.toString(), CryptoJS.SHA256(key), { mode: CryptoJS.mode.ECB });
        return CryptoJS.enc.Utf8.stringify(decrypted);
    } catch (error) {
        console.log(error);
    }
}

function GES(body, secret) {
    var encryptedBody = {};
    for (var prop in body) {
        if (body.hasOwnProperty(prop)) {
            var value = body[prop];
            if (Array.isArray(value)) {
                var encryptedArray = value.map(item => convertToHash2(String(item), secret));
                encryptedBody[prop] = encryptedArray;
            } else if (typeof value === 'object' && value !== null) {
                var encryptedObject = GES(value, secret); // Recursively decrypt nested objects
                encryptedBody[prop] = encryptedObject;
            } else {
                var encryptedValue = convertToHash2(String(value), secret);
                encryptedBody[prop] = encryptedValue;
            }
        }
    }
    return encryptedBody;
}
function GDS(body, secret) {
    var decryptedBody = {};
    for (var prop in body) {
        if (body.hasOwnProperty(prop)) {
            var value = body[prop];
            if (Array.isArray(value)) {
                var decryptedArray = value.map(item => convertToText2(item, secret));
                decryptedBody[prop] = decryptedArray;
            } else if (typeof value === 'object' && value !== null) {
                var decryptedObject = GDS(value, secret); // Recursively decrypt nested objects
                decryptedBody[prop] = decryptedObject;
            } else {
                var decryptedValue = convertToText2(value, secret);
                decryptedBody[prop] = decryptedValue;
            }
        }
    }
    return decryptedBody;
}
function GDS2(body, secret) {
    var decryptedBody = {};
    for (var prop in body) {
        if (body.hasOwnProperty(prop)) {
            var value = body[prop];
            if (Array.isArray(value)) {
                var decryptedArray = value.map(item => convertToText3(item, secret));
                decryptedBody[prop] = decryptedArray;
            } else if (typeof value === 'object' && value !== null) {
                var decryptedObject = GDS2(value, secret); // Recursively decrypt nested objects
                decryptedBody[prop] = decryptedObject;
            } else {
                var decryptedValue = convertToText3(value, secret);
                decryptedBody[prop] = decryptedValue;
            }
        }
    }
    return decryptedBody;
}

function GESRecords(records, secretKey) {
    if (!Array.isArray(records)) {
        // Encrypt single object directly
        return GES(records, secretKey);
    }

    // Encrypt array of records
    return records.map((record) => GES(record, secretKey));
}
function GDSRecords2(records, secretKey) {
    if (!Array.isArray(records)) {
        // Encrypt single object directly
        return GDS2(records, secretKey);
    }

    // Encrypt array of records
    return records.map((record) => GDS2(record, secretKey));
}
function GDSRecords(records, secretKey) {
    if (!Array.isArray(records)) {
        // Encrypt single object directly
        return GDS(records, secretKey);
    }

    // Encrypt array of records
    return records.map((record) => GDS(record, secretKey));
}

// Number Encryption function
function encryptNumber(number, key) {
    // Convert to string if not already a string
    const inputString = typeof number === 'string' ? number : number.toString();
    const encrypted = CryptoJS.AES.encrypt(inputString, key).toString();
    return encrypted;
}

// Number Decryption function
function decryptNumber(encrypted, key) {
    const decryptedBytes = CryptoJS.AES.decrypt(encrypted, key.toString());
    const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedValue; // Return as a string
}


export { GES, GDS, GDSRecords, GDSRecords2, GESRecords, encryptNumber, decryptNumber }