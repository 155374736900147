import React, { useState, useEffect, useRef } from "react";
import "./Home.scss";
import Cookies from "js-cookie";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as CgIcons from "react-icons/cg";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { Button, Pagination, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { GES, GDS, GDSRecords } from "../ENDE";
import axios from 'axios';
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

const Home = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);
  const [userdata, setuserdata] = useState(null);
  const fetchdata = async () => {
    var lpgid = localStorage.getItem('lpg_id')
    if (lpgid.length > 6) {
      console.log('in' + lpgid.length);
      console.log(lpgid);
      try {
        setloading(true);
        await axios({
          url: `  ${process.env.REACT_APP_API_LINK}/getpersonaldetailslpgormobile/` + lpgid,
          method: 'GET',
          responseType: 'json',
        }).then(response => {
          console.log(response.data);
          let resData = GDS(response.data, apiDE);
          console.log(resData);
          // console.log(response.data.length);
          // console.log(Object.keys(response.data).length);

          console.log(
            'getpersonaldetailslpgormobile==========================',
          );
          if (Object.keys(resData).length > 0) {
            setuserdata(resData);
            localStorage.setItem('userdata', JSON.stringify(resData))
            // setuserdata(response.data.name_as_pan);
            setloading(false);
            console.log('inside');
          } else {
            console.log('No details found');
            setuserdata('No details found');
            setloading(false);
          }
        });
      } catch (err) {
        console.log('error fetching getpersonaldetails');
        console.log(err);
        setloading(false);
      }
    } else {
      alert('Incorrect LPG ID');
      setloading(false);
    }
  };
  useEffect(() => {
    fetchdata()
  }, [])
  function TableStatus({ text }) {
    const textx = text.toLowerCase();
    // console.log(textx);
    // console.log(textx === 'rejected');
    const color =
      textx !== "approved"
        ? "#EB5757"

        : "#12A474";
    const bgcolor =
      textx !== "approved"
        ? "#FFDBDB"
        : "#BBF3E0";

    var textx2 = text !== "approved" ? "KYC PENDING" : "KYC COMPLETED";
    return (
      <span
        style={{
          backgroundColor: bgcolor,
          color: color,
          fontSize: 12,
          paddingTop: 5,
          paddingBottom: 5,
          paddingLeft: 10,
          paddingRight: 10,
          borderRadius: 8,
          fontWeight: 'bold',
          textTransform: "capitalize",
        }}
      >
        {textx2}
      </span>
    );
  }
  var headers = {
    "Personal Details": [
      { g: "Mobile", c: "mobile" },
      { g: "Date of Birth", c: "dob" },
      { g: "Monthly Income", c: "monthlyincome" },
      { g: "Employment", c: "employment" },
      { g: "Name as per Aadhaar", c: "name_as_pan" },
      { g: "Gender", c: "gender" },
      { g: "Aadhar Number", c: "aadhar_no" },
      { g: "PAN Number", c: "pan_no" }
    ],

    "Address Details": [
      { g: "Address Line 1", c: "address_line1" },
      { g: "Address Line 2", c: "address_line2" },
      { g: "State", c: "state" },
      { g: "City", c: "city" },
      { g: "Pincode", c: "pincode" },
      { g: "District", c: "district" }
    ],
    "LPG Details": [
      { g: "KYC", c: "kyc" },
      { g: "LPG Distribution ID", c: "lpg_dist_id" },
      { g: "LPG ID", c: "lpg_id" },
      { g: "LPG Distribution Name", c: "lpg_dist_name" }
    ]
  };


  if (loading || !userdata) {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            minHeight: "100vh",
            alignItems: "center",
            justifyContent: "center",
            // size: "5rem",
          }}
        >
          <Spinner
            animation="border"
            role="status"
            style={{ width: "4rem", height: "4rem" }}
          >
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </>
    );
  } else {

    return (
      <div className="homescreen">

        <div className="data-display">
          {Object.keys(headers).map((section, index) => (
            <div className="section" key={index}>
              <h2>{section}</h2>
              <div className="data-list">
                {headers[section].map((field) => (
                  <div className="field" key={field.c}>
                    <span className="label">{field.g}:</span>

                    {field.c === 'kyc' ?
                      <TableStatus text={userdata[field.c]} /> :
                      <span className="value">{userdata[field.c]}</span>}

                  </div>
                ))}

                {section === 'LPG Details' ?
                  <div className="field">
                    <Button variant="primary fw-bold mx-1 my-2 " onClick={() => {
                      navigate('/UserProfileForm');

                    }}>
                      {userdata['kyc'] === 'approved'
                        ? ('Edit Profile')
                        : ('Complete KYC')}
                    </Button></div> : <></>}
              </div>




            </div>
          ))}
        </div>
        <div className="bttongroup" style={{ paddingTop: 20 }}>

          <Button variant="primary fw-bold mx-1 my-2 "
            style={{
              width: "150%"
            }}
            onClick={async () => {

              if (userdata.kyc !== 'approved') {
                alert(
                  userdata['lpg_id'] +
                  "'s KYC is Pending.Complete KYC for refill.",
                );
              } else {
                console.log('-----------------------------------');

                console.log(
                  `${process.env.REACT_APP_API_LINK}/refilldetailsLPG/` +
                  userdata.lpg_id,
                );
                try {
                  setloading(true);
                  await axios({
                    url: `${process.env.REACT_APP_API_LINK}/CheckCustomerLoanTakenOrNot`,
                    method: 'POST',
                    responseType: 'json',
                    // important
                    data: GES(userdata, apiEN),
                  }).then(async response => {
                    if (response.data === 'LOAN TAKEN') {
                      alert(
                        userdata.name_as_pan.toUpperCase() +
                        ' you have already taken a loan. ',
                      );
                    } else {
                      navigate('/UserRefillForm');

                    }
                    setloading(false);
                  });
                } catch (err) {
                  console.log('error fetching ');
                  setloading(false);
                  console.log(err);
                }
              }
            }}>
            Apply for LPG Loan
          </Button>
        </div>
      </div>
    );
  }
};

export default Home;