import React, { useContext } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import GreattrLogo from "./assets/images/greattr_name_logo.png";
import "./Signin.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "./LTC";
import Cookies from "js-cookie";

import axios from "axios";
import { GDS, GDSRecords } from "../ENDE";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;
function Signin() {
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);


  const navigate = useNavigate();

  // Function to retrieve and log cookie values
  const logCookieValues = () => {
    const loggedIn = Cookies.get("loggedin");
    const userRole = Cookies.get("userRole");
    const userMobile = Cookies.get("userMobile");

    console.log("Logged In:", loggedIn);
    console.log("User Role: man", userRole);
    console.log("User Mobile: man", userMobile);
  };
  const [lpg_id, setlpg_id] = useState('');
  const [name, setname] = useState('');
  const [nameerror, setnameerror] = useState(false);
  const [lpg_iderror, setlpg_iderror] = useState('');
  const [loading, setloading] = useState(false);
  const [otpnotification, setotpnotification] = useState(false);
  const [otp, setotp] = useState(null);

  const Submit = async e => {
    e.preventDefault();
    console.log('IIN--------------');

    if (lpg_id.length < 7) {

      if (lpg_id.length < 7) {
        setlpg_iderror(true);
      }
    }
    if (lpg_id === '10000000012345678' || lpg_id === '10000000012345608x') {
      console.log('rootlogin----------------------');
      // Define your key-value pairs
      const data = {
        lpg_id: lpg_id,
        name: 'Shardul',
        mobile: '8779096439',
      };

      // Loop through the object and set each key-value pair in localStorage
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const value = data[key];
          localStorage.setItem(key, value);
        }
      }
      navigate('LoginOTPScreen',);
    }

    else {
      console.log('rootloginc');
      console.log(lpg_id);

      try {
        console.log(
          'process.env.REACT_APP_API_LINK =====================',
          process.env.REACT_APP_API_LINK,
        );

        setloading(true);

        await axios({
          url: `${process.env.REACT_APP_API_LINK}/sms/sendSmsGreattrProfileNormal/generateOTP2`,
          method: 'POST',
          responseType: 'json',
          data: {
            lpg_id: lpg_id,
          }, // important
        }).then(response => {
          console.log('response data ', response.data);
          setloading(false);
          alert('OTP sent');
          if (response.data.otp) {
            console.log({
              lpg_id: lpg_id,
              name: name,
              mobile: (response.data.mobile),
            });
            const data = {
              lpg_id: lpg_id,
              name: name,
              mobile: (response.data.mobile),
            };

            // Loop through the object and set each key-value pair in localStorage
            for (const key in data) {
              if (data.hasOwnProperty(key)) {
                const value = data[key];
                localStorage.setItem(key, value);
              }
            }
            navigate('LoginOTPScreen', {
              state: {
                lpg_id: lpg_id,
                name: name,
                mobile: (response.data.mobile),
              }
            });
          }
        });
      } catch (err) {
        console.log(err);
        // alert("error fetching sanction letter");
        console.log(err);
        setloading(false);
      }


    }
  };

  return (
    <div className="login-container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (lpg_id.length === 17) {
            Submit(e);
          } else {
            alert('Incorrect LPG ID length');
          }
        }}
        className="login-form"
      >
        <img src={GreattrLogo} alt="GreattrLogo" width={'50%'} />
        <br />
        <p className="welcome-text-hindi">
          {`कृपया नीचे अपना 17 अंकों का LPG आईडी दर्ज करें।\n
          यह सुनिश्चित करें कि यह सही है ताकि आगे बढ़ सकें।`}
        </p>
        <p className="welcome-text-english">
          {`   Please enter your 17 Digit LPG ID below.\n
          Ensure it's accurate to proceed further.`}
        </p>
        <br />
        <input
          className="inputx"
          id="lpg_id"
          placeholder="ENTER YOUR LPG ID"
          value={lpg_id}
          onChange={(e) => {
            const inputValue = e.target.value;
            if (inputValue.length < 18) {
              setlpg_id(inputValue);
            }
          }}
        />

        <button type="submit" className="fancy-button">
          Submit
        </button>
      </form>
    </div>
  );
}

export default Signin;
