import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import OtpInput from "react-otp-input";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import * as BiIcons from "react-icons/bi";
import { IconContext } from "react-icons";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import './LoginOTP.scss'
import { GDSRecords, GES, encryptNumber } from "../ENDE";
import { LoginContext } from "./LTC";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

// Import other dependencies and styles as needed

const LoginOTP = ({ route, history }) => {
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContext);
  const location = useLocation();

  var routeparams = {
    lpg_id: localStorage.getItem('lpg_id'),
    mobile: localStorage.getItem('mobile'),
    name: localStorage.getItem('name'),
  }
  const [loading, setloading] = useState(false);
  const [sentOtp, setsentOtp] = useState(null);
  const [otpinput, setotpinput] = useState(0);
  const [otp, setOTP] = useState(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8) {
      e.preventDefault(); // Prevent the default behavior of the backspace key

      if (index > 0) {
        const newOTP = [...otp];
        newOTP[index] = ""; // Clear the current input
        setOTP(newOTP);

        // Move focus to the previous input field
        inputRefs[index - 1].current.focus();
      } else if (index === 0) {
        const newOTP = ["", ...otp.slice(1)]; // Clear the first input
        setOTP(newOTP);

        // Stay on the current input field
        inputRefs[index].current.focus();
      }
    }
  };
  const handleChange = (e, index) => {
    const value = e.target.value;

    // Ensure the input is a number and limit it to one character
    if (/^[0-9]$/.test(value) && index >= 0 && index <= 5) {
      // Create a copy of the OTP array
      const newOTP = [...otp];

      // Update the OTP array with the new value at the specified index
      newOTP[index] = value;

      // Set the updated OTP
      setOTP(newOTP);

      // Move focus to the next input field if available
      if (index < 5 && value !== "") {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === "" && index > 0) {
      // Allow backspacing by moving focus to the previous input field
      inputRefs[index - 1].current.focus();
    }
  };

  const onSubmit = async (token) => {
    console.log(token);
    console.log(location.state.mobile);
    console.log(otp);
    var otpx = otp.join('');
    console.log(otpx);
    if (otpx.length !== 6) {
      alert('Please Enter the correct OTP ');

    }
    else {
      try {
        setloading(true);
        await axios({
          url: `${process.env.REACT_APP_API_LINK}/getOTP`,
          method: 'POST',
          responseType: 'json',
          data: GES(
            {
              mobile: location.state.mobile,
              otp: otpx,
            },
            apiEN,
            //yes
          ), // important
        }).then(response => {
          let resData = response.data;
          console.log('response data ---------------', resData);
          setloading(false);

          if (resData === 'SUCCESS') {
            setIsLoggedIn(true)
            document.cookie = "loggedin=true; path=/";
            document.cookie = `lpgid=${routeparams.lpg_id}; path=/`;
            navigate("/home");
            console.log('otp success');
          }
          if (resData === 'WRONG OTP') {
            console.log('otp wrong');
            alert('Please Enter the correct OTP ');
          }
        });
      } catch (err) {
        // alert("error fetching sanction letter");
        console.log(err);
      }
    }



  };

  const getOTP = async () => {
    console.log(routeparams.mobile);
    await axios({
      url: `${process.env.REACT_APP_API_LINK}/sms/sendSmsGreattrProfileNormal/generateOTP2`,
      method: 'POST',
      responseType: 'json',
      data: {
        lpg_id: routeparams.lpg_id,
      }, // important
    }).then(response => {
      // console.log('response data ', response.data.mobile);
      setloading(false);
      alert('OTP sent');

    });
  };

  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(timeOutCallback, 1000);
    }
  }, [timer, timeOutCallback]);

  const resetTimer = () => {
    if (timer) {
      console.log("INxxada");
      setTimer(60);
    }
  };






  return (
    <div className="OTPscreen">
      <Navbar bg="transparent">
        <Container fluid>
          <Navbar.Brand>
            {" "}
            <IconContext.Provider value={{ color: " #3a0360", size: "30px" }}>
              <Link
                to="/login"
                className="sidebarx"
                style={{ color: " #3a0360", textDecoration: "none" }}
              >
                <div className="back-button" >
                  <BiIcons.BiArrowBack className="back-icon" style={{ color: 'white' }} />
                  Back
                </div>
              </Link>
            </IconContext.Provider>
          </Navbar.Brand>
        </Container>
      </Navbar>
      <div className="OTPcontent">
        <h2>OTP Verification</h2>
        
      </div>
      <div className="OTPform">
        <div className="otp-container">
        <h3 style={{paddingTop: 15}}>Enter the OTP sent to Mobile Number {localStorage.getItem("phone")}</h3>
        <div className="digit-group">
          <div style={{
            alignSelf: 'center'
          }}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                style={{
                  textAlign: 'center'
                  , fontSize: '2rem',
                }}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={inputRefs[index]}
              />
            ))}
          </div>
        </div>
        <button onClick={onSubmit}>Submit OTP</button>
        <div className="loginwithusernamepassword">
          Dont receive the OTP?
          <span /> <b
            onClick={() => {
              console.log();
              // resetTimer();
              getOTP();
              setTimer(60);
            }}
          >RESEND OTP</b>
        </div>
        </div>
      </div>
    </div>
  )

  // return (
  //   <div className={["profile-otp-container"]}>
  //     <div className={myStyle.title}>{("OTPTitle")}</div>
  //     <div className={myStyle.subtitle}>{("OTPSubtitle")}</div>
  //     <div className={["otp-input-container"]}>
  //       <div className={["sms-code-label"]}>{("SMSCode")}</div>
  //       <input
  //         type="number"
  //         maxLength="6"
  //         value={otpinput}
  //         onChange={(e) => {
  //           setotpinput(e.target.value);
  //           console.log(e.target.value);
  //         }}
  //       />
  //     </div>
  //     <div className={myStyle.subtitle}>{("OTPSubtitle2")}</div>
  //     {timer < 1 ? (
  //       <div>
  //         <button
  //           className={["resend-otp-button"]}
  //           onClick={() => {
  //             console.log();
  //             // resetTimer();
  //             getOTP();
  //             setTimer(60);
  //           }}
  //         >
  //           {("ResendOTP")}
  //         </button>
  //       </div>
  //     ) : (
  //       <div className="langtitle">
  //         <div className={["timer-label"]}>
  //           {("ResendOTP")} ({timer < 0 ? 0 : timer})
  //         </div>
  //       </div>
  //     )}
  //     <button
  //       title="Submit"
  //       className={["resend-otp-button"]}
  //       onClick={async () => {
  //         console.log(process.env.REACT_APP_API_LINK);
  //         console.log(otpinput);
  //         console.log(routeparams.otp);
  //         try {
  //           setloading(true);
  //           console.log("routeparams.mobile");
  //           console.log(routeparams.mobile);
  //           console.log("routeparams.mobile");
  //           const response = await axios.post(`${process.env.REACT_APP_API_LINK}/getotp2`, {
  //             mobile: routeparams.mobile,
  //             lpg_id: routeparams.lpg_id,
  //             otpinput: otpinput,
  //           });

  //           console.log(response.data);
  //           setloading(false);

  //           if (response.data.status === "SUCCESS") {
  //             onSubmit(response.data.token);
  //           }
  //           if (response.data === "WRONG OTP") {
  //             // Replace with your web alert
  //             console.log("Please Enter the correct OTP ");
  //           }
  //         } catch (err) {
  //           // Replace with your web error handling
  //           console.log(err);
  //         }

  //         console.log("clikeds");
  //       }}
  //     >
  //       <div style={myStyle.button.text}> {("proceed")}</div>
  //     </button>
  //   </div>
  // );
};

export default LoginOTP;
