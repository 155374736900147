import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Customer from "./components/Customer";
import Payments from "./components/Payments";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { createContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFileUpload } from "@fortawesome/free-solid-svg-icons";
import AdminDashboard from "./components/AdminDashboard";
import Distributor from "./components/Distributor";
import DistributorwiseOverdue from "./components/DistributorwiseOverdue";
import DistributorwiseOverdueEMIs from "./components/DistributorwiseOverdueEMIs";
import CustomersOverdue from "./components/CustomersOverdue";
import EMIsOverdue from "./components/EMIsOverdue";
import "./custom.scss";
import GreattrLogo from "./components/assets/images/logo-DH.png";
import Signin from "./components/Signin";
import { LoginContext } from "./components/LTC";
import Loggedout from "./components/Loggedout";
import { React, useEffect } from "react";
import Charts from "./components/Charts";
import Cookies from "js-cookie";
import DistributorData from "./components/DistributorData";
import Upload from "./components/Upload";
import SalesOfficer from "./components/SalesOfficer";
import TerritoryManager from "./components/TerritoryManager";
import LoginOTP from "./components/LoginOTP";
import Home from "./components/Home";
import UserProfileForm from "./components/UserProfileForm";
import SignzyConfirmScreen from "./components/SignzyConfirmScreen";
import UserRefillForm from "./components/UserRefillForm";
import Refill4 from "./components/RefillLpg4Sanction";
import KFS from "./components/KFS";
import RefillOTPScreen from "./components/RefillOTPScreen";
import WhyChooseUs from "./WhyChooseUs";
function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");

  const [searchValue, setSearchValue] = useState("");
  const [searchValueTM, setSearchValueTM] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsTM, setFilteredItemsTM] = useState([]);

  const tmData = JSON.parse(sessionStorage.getItem("TMData"));
  const soData = JSON.parse(sessionStorage.getItem("SOData"));

  const navigate = useNavigate();

  // Function to handle tab selection for sorting
  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);

    // Filter the items based on the search value
    const filtered = JSON.parse(sessionStorage.getItem("SOData")).filter((x) =>
      x.name_of_sales_officer.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItems(filtered);
  };


  const handleSearchChangeTM = (event) => {
    const value = event.target.value;
    setSearchValueTM(value);

    // Filter the items based on the search value
    const filtered = JSON.parse(sessionStorage.getItem("TMData")).filter((x) =>
      x.name_of_territory_manager.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredItemsTM(filtered);
  };
  useEffect(() => {
    // Load count from sessionStorage when the component mounts
    const savedCount = sessionStorage.getItem("loggedin");
    if (savedCount) {
      setIsLoggedIn(savedCount);
    } else {
      // If sessionStorage doesn't have "loggedin", check cookies
      const isLoggedInCookie = getCookie("loggedin");
      if (isLoggedInCookie) {
        setIsLoggedIn(isLoggedInCookie);
      }
    }
  }, []);

  const location = useLocation();
  // return (<WhyChooseUs />)
  return (
    <LoginContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      {isLoggedIn ? (
        <>
          <Navbar bg="dark" variant="dark" className="navbg" expand="lg">
            <Navbar.Brand href="/Dashboard">
              <img src={GreattrLogo} alt="GreattrLogo" width={120} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">

              </Nav>
            </Navbar.Collapse>
            <LinkContainer className="mr-40" to="/">
              <Nav.Link
                onClick={() => {


                  setIsLoggedIn(false);
                  Cookies.remove("loggedin", { path: "/" });
                  Cookies.remove("lpgid", { path: "/" });

                  Cookies.remove("userRole", { path: "/" });
                  Cookies.remove("userMobile", { path: "/" });
                  Cookies.remove("userMobileTM", { path: "/" });
                  Cookies.remove("Username", { path: "/" });
                  Cookies.remove("password", { path: "/" });
                  // Clear sessionStorage
                  sessionStorage.clear();

                  // Clear localStorage
                  localStorage.clear();



                }}
              >
                {" "}
                <p
                  style={{ color: "white" }}
                >
                  Logout</p>
              </Nav.Link>
            </LinkContainer>
          </Navbar>

          <Routes>
            <Route path="/Dashboard" element={<AdminDashboard />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/loggedout" element={<Loggedout />} />
            <Route path="/UserProfileForm" element={<UserProfileForm />} />
            <Route path="/UserRefillForm" element={<UserRefillForm />} />
            <Route path="/KFS" element={<KFS />} />
            <Route path="/Refill4" element={<Refill4 />} />
            <Route path="/RefillOTPScreen" element={<RefillOTPScreen />} />

            <Route path="/SignzyConfirmScreen" element={<SignzyConfirmScreen />} />

          </Routes>
        </>
      ) : location.pathname === "/" || location.pathname === "/LoginOTPScreen" ? null : (
        <Loggedout />
      )}
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/LoginOTPScreen" element={<LoginOTP />} />

      </Routes>


      {/* <Payments /> */}
    </LoginContext.Provider>
  );
}

export default App;

const getCookie = (cookieName) => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) {
      return cookie.substring(cookieName.length + 1); //Return Cookie value
    }
  }
  return null; //If Cookie not found
};
