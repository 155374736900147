import React, { useState, useEffect, useRef } from "react";
import "./Home.scss";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import axios from "axios";
import * as GiIcons from "react-icons/gi";
import * as CgIcons from "react-icons/cg";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import { Button, Pagination, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { GES, GDS, GDSRecords } from "../ENDE";
import "./KFS.scss";
import logo from "./assets/jainexlogo.png";
import OtpInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;


const Refill4 = () => {
  const location = useLocation();
  //VARS
  const [loading, setloading] = useState(false);
  const [progress, setprogress] = useState(70);
  const [checked, setchecked] = useState(false);
  const nameForm = useRef(null);
  const refilldata = location.state.refilldata;
  const fromApproveButton = location.state.fromApproveButton;
  console.log(fromApproveButton);
  const navigate = useNavigate();
  const currdate = new Date();
  console.log(location.state.refilldata);
  const [otpform, setotpform] = useState(null);
  var kfdata = location.state.refilldata;
  const getOTP = async event => {
    console.log(kfdata.mobile);

    try {
      setloading(true);
      await axios({
        url: `${process.env.REACT_APP_API_LINK}/CheckCustomerLoanTakenOrNot`,
        method: 'POST',
        responseType: 'json',
        // important
        data: GES(
          { ...kfdata }
          , apiEN),
      }).then(async response => {
        if (response.data === 'LOAN TAKEN') {
          alert(
            kfdata.name_as_pan + ' has already taken a loan. ',
          );
          navigate('/home');

        }
        else {
          await axios({
            url: `${process.env.REACT_APP_API_LINK}/sms/sendSmsGreattrLoanAssisted/generateOTP`,
            method: 'POST',
            responseType: 'json',
            // important
            data: GES(
              {
                mobile: kfdata.mobile,
              }
              , apiEN),
          }).then(response => {
            console.log("response.dataresponse.dataresponse.dataresponse.dataresponse.data");
            console.log(response.data);
            let resData = GDSRecords(response.data, apiDE);
            console.log(resData);
            setloading(false);
            alert("OTP SENT")
            if (resData.otp) {
              console.log(resData);
              console.log(location.state.refilldata);
              navigate('/refillOTPScreen', {
                state: {
                  kfdata: location.state.refilldata,
                  otp: resData.otp,
                }
              });
            }
          });
        }
      });
    } catch (err) {
      // alert("error fetching sanction letter");
      console.log(err);
    }
  };

  const onChangeCheckBox = async (event) => {
    const refilldata = location.state.refilldata;
    console.log(refilldata.lpg_mobile);
    getOTP();
  };
  const divRef = useRef(null);
  var dateoptions = { year: "numeric", month: "numeric", day: "numeric" };
  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  var EstimatedDate = addDays(currdate, 2);
  var dateoptions2 = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  // const Submit = async (event) => {
  //   // event.preventDefault();
  //   // alert(name + phone);
  //   const refilldata = location.state.refilldata;
  //   console.log(refilldata);

  //   if (loading) {
  //     console.log("error");
  //     alert({
  //       icon: "warning",
  //       title: "Please Fill All the fields Correctly",
  //     });
  //   } else {
  //     console.log("upsert");
  //     try {
  //       setloading(true);

  //       await axios({
  //         url: `${process.env.REACT_APP_API_LINK}/lpg_details`,
  //         method: "POST",
  //         responseType: "json",
  //         data: refilldata, // important
  //       }).then((response) => {
  //         console.log(response);
  //         setloading(false);
  //         setprogress(100);
  //         alert({
  //           title: "Applied for Refill LPG loan ,Please wait for Approval",
  //           icon: "success",
  //           button: "Proceed to Home",
  //         });
  //         navigate("/home");
  //       });
  //     } catch (err) {
  //       // alert("error fetching sanction letter");
  //       console.log(err);
  //     }
  //   }
  // };

  return (
    <div
      ref={divRef}
      style={{
        display: "flex",
        alignSelf: "center",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        // size: "5rem",
      }}
    >
      {loading ? (
        <div style={{ alignSelf: "center" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="Refillscreen3">
          <div style={{ padding: "5%" }}>
            {/* Header start */}
            <br />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1 style={{ alignSelf: "center", flex: 4, fontSize: "1.5rem" }}>
                Jainex Finance Limited
              </h1>
              <img
                // width={50}
                height={40}
                src={logo}
                style={{
                  alignSelf: "center",
                  flex: 1,
                }}
                alt=""
              />
            </div>
            <p
              style={{
                alignSelf: "center",
                textAlign: "center",
                fontSize: "0.8rem",
              }}
            >
              CIN : U65921AS1993PLC003858 Email : jainex@jainexfin.com Hand
              Phone: +91-9818093311 Suite No. 304-305, Rustomjee Central Park,
              Andheri Kurla Road, Andheri East, Mumbai - 400 093
            </p>

            {/* Header end */}

            <h1
              style={{
                textAlign: "center",
              }}
            >
              Loan Approval Cum Acceptance Letter
            </h1>
            <p>Date: {currdate.toLocaleDateString()}</p>
            <p></p>
            {/* <p>Loan account Ref</p> */}
            <p></p>
            <p>
              Name: Mr/Mrs {location.state.refilldata.name}
              <br />
              Address 1:{location.state.refilldata.address_line1}
            </p>
            <p>Address 2:{location.state.refilldata.address_line2}</p>
            <p>
              Pin Code:
              {location.state.refilldata.pincode}
            </p>
            <p>
              Estimated Delivery Date :
              {EstimatedDate.toLocaleDateString("en-UK", dateoptions2)}
            </p>

            <p>
              We are sending you this letter to confirm that your loan has been
              approved by Jainx Finance Private Limited (“NBFC”) under Pradhan
              Mantri Ujjawala Yojna for purchasing LPG gas cylinder for a period
              of 45 to 60 days as per the terms below.
            </p>
            <p style={{ color: "red" }}>
              *The repayment date shall vary according to delivery date
            </p>
            <table style={{ border: "2px solid black", height: "10%" }}>
              <tbody>
                <tr style={{ height: "10%" }}>
                  <td
                    style={{
                      width: "5%",
                      border: "1px solid black",
                    }}
                  >
                    <p className="s1">Loan Amount</p>
                  </td>
                  <td
                    style={{
                      width: "10%",
                      border: "1px solid black",
                    }}
                    colSpan={6}
                  >
                    <p>Rs.600/- (Rupees Six Hundred Only)</p>
                  </td>
                </tr>
                <tr style={{ height: "10%" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s1">Interest</p>
                  </td>
                  <td
                    style={{ border: "2px solid black", height: "10%" }}
                    colSpan={6}
                  >
                    <p className="s2">Zero Percent Interest</p>
                  </td>
                </tr>
                <tr style={{ height: "12" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s1">Processing Charges</p>
                  </td>
                  <td
                    style={{ border: "2px solid black", height: "10%" }}
                    colSpan={6}
                  >
                    <p className="s2">Zero Processing Charges</p>
                  </td>
                </tr>
                <tr style={{ height: "12" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s1">Tenure</p>
                  </td>
                  <td
                    style={{ border: "2px solid black", height: "10%" }}
                    colSpan={6}
                  >
                    <p className="s2">
                      Total 35 – 42 Days (Maximum 5 weeks / weeks)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14" }}>
                  <td
                    style={{ border: "2px solid black", height: "10%" }}
                    rowSpan={6}
                  >
                    <p className="s1">EMI Schedule</p>
                  </td>

                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">I Repayment</p>
                  </td>

                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">Rs. 200</p>
                  </td>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">
                      {addDays(EstimatedDate, 7).toLocaleDateString(
                        "en-UK",
                        dateoptions
                      )}
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">II Repayment</p>
                  </td>

                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">Rs. 100</p>
                  </td>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">
                      {addDays(EstimatedDate, 14).toLocaleDateString(
                        "en-UK",
                        dateoptions
                      )}
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">III Repayment</p>
                  </td>

                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">Rs. 100</p>
                  </td>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">
                      {addDays(EstimatedDate, 21).toLocaleDateString(
                        "en-UK",
                        dateoptions
                      )}
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">IV Repayment</p>
                  </td>

                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">Rs. 100</p>
                  </td>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">
                      {addDays(EstimatedDate, 28).toLocaleDateString(
                        "en-UK",
                        dateoptions
                      )}
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "14" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">V Repayment</p>
                  </td>

                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">Rs. 100</p>
                  </td>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s2">
                      {addDays(EstimatedDate, 35).toLocaleDateString(
                        "en-UK",
                        dateoptions
                      )}
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11" }}>
                  <td
                    style={{ border: "2px solid black", height: "10%" }}
                    colSpan={5}
                  >
                    <p className="s4">
                      The repayment shall start from the date of delivery
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "34" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s1">
                      Charges on Default<i>(Non-</i>
                      payment of loan at the end of Tenure)
                    </p>
                  </td>
                  <td
                    style={{ border: "2px solid black", height: "10%" }}
                    colSpan={6}
                  >
                    <p className="s2">
                      At default, the customer’s deposit of <b>Rs.600 </b>
                      towards LPG cylinder paid to the distributor shall be
                      forfeited and the distributor shall take back the LPG
                      cylinder from the customer
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                paddingTop: "1",
                paddingLeft: "6",
                textIndent: "0",
                lineHeight: "112%",
                textAlign: "left",
              }}
            >
              All dispute, if any arising out of or in connection with or as a
              result of the offer or otherwise relating hereto shall be subject
              to the exclusive jurisdiction of the competent Courts /Tribunals
              in Mumbai only.
            </p>

            <p
              style={{
                paddingTop: "7",
                paddingLeft: "6",
                textIndent: "0",
                textAlign: "justify",
              }}
            >
              Upon submission of the customer SI debit authorization form from
              your bank and KYC documents, we shall proceed further to disburse
              your loan in your account. The list of documents is as below:
            </p>
            <table
              style={{ borderCollapse: "collapse", marginLeft: "6.12999" }}
              cellSpacing={0}
            >
              <tbody>
                <tr>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s3">
                      Identity Proof - Driving License, One Photo ID (Aadhaar
                      card or Voter ID Card), BPL Ration Card, etc
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "22" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s3">
                      Address Proof - Copy of Passport, Lease Agreement,
                      Telephone/Electricity/Water Bill, Ration Card,
                    </p>
                    <p className="s3">Flat Allotment/Possession Letter</p>
                  </td>
                </tr>
                <tr style={{ height: "22" }}>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s3">
                      BPL (Below Poverty Line) Certificate that has been
                      authorized by the Panchayat Pradhan or the
                    </p>
                    <p className="s3">Municipality Chairman</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s3">One Recent Passport Size Photograph</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s3">
                      Aadhaar Card Copy of all the members of the household
                    </p>
                  </td>
                </tr>
                <tr>
                  <td style={{ border: "2px solid black", height: "10%" }}>
                    <p className="s3">Bank Passbook Copy of last one year</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <ul id="l1">
              <li data-list-text="•">
                <p>
                  The Borrower undertake to maintain sufficient balance to cover
                  the above EMI amount (and charges if any) on the above due
                  date and He/She understand that the failure to repay the loan
                  amount shall be treated as a default in repayment of dues and
                  all charges/penalty /levies as covered by this Sanction Letter
                  will hold good towards the non-payment of obligations.
                </p>
              </li>{" "}
              <li data-list-text="•">
                <p>
                  Note: Cash amount not accepted by company. Online payment
                  through UPI and/or SI debit instruction with customer bank is
                  allowed.{" "}
                </p>
              </li>
              <li data-list-text="•">
                <p>Thanking you, Yours Truly,</p>

                <p
                  style={{
                    paddingLeft: "6",
                    textIndent: "0",
                    textAlign: "left",
                    color: "green",
                  }}
                >
                  Jainex Greattr
                  {/* {location.state.refilldata.name} */}
                </p>
                <p />
                <p>Verified</p>
              </li>
            </ul>
            <p>Verified Sanction by OTP :</p>
            <p>
              Please Click in the box or anywhere in the area for the OTP
            </p>

            <button onClick={() => {
              console.log('clikeds');
              getOTP();
            }}>
              CONFIRM TO THE ABOVE THROUGH ONLINE/ELECTRONIC MEANS. | HEREBY
              CONFIRM THAT ACCEPTANCE OF THE ABOVE TERMS AND CONDITIONS WAS
              COMPLETED ONLINE FOR MY CONVENIENCE. “APPLIED, ACCEPTED,
              AUTHENTICATED, SIGNED AND DELIVERED BY ME BY ENTERING THE
              ONE-TIME PASSWORD (OTP) ON {currdate.toLocaleDateString()} AT{" "}
              {currdate.getHours()}:{currdate.getMinutes()} THROUGH IP
              ADDRESS 127.0.0.1"
            </button>



            {/* <button
              onClick={() => {
                Submit();
              }}
            >
              {" "}
              Proceed
            </button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Refill4;
