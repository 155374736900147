import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { GES, GDS, GDSRecords } from "../ENDE";
import axios from "axios";
import './signzy.scss'
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;
function SignzyConfirmScreen() {
  const [loading, setloading] = useState(false)
  const navigate = useNavigate();

  var datax = JSON.parse(localStorage.getItem("signzydata"))

  var signzydata = datax["signzydata"]
  var p1data = datax["p1data"]
  p1data["aadhar_no"] = p1data["aadhar_no"].replace(/x/g, '');
  function convertToDate(dateString) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    let d = dateString.split('/');
    let dat = d[2] + '-' + d[1] + '-' + d[0];
    return dat;
  }
  async function getOTP(uri, p1data) {
    console.log(p1data);
    try {
      setloading(true);
      await axios({
        url: `${process.env.REACT_APP_API_LINK}/sms/sendSmsGreattrProfileAssisted/generateOTP`,
        method: 'POST',
        responseType: 'json',
        // important
        data: GES(
          {
            mobile: p1data.mobile,
          },
          apiEN,
        ),
      }).then(response => {
        let resData = GDSRecords(response.data, apiDE);
        console.log(resData[0]);
        setloading(false);
        alert('OTP sent');
        if (resData.otp) {
          navigate('/ProfileOTPSignzyScreen', {
            p1data: {
              ...p1data,
              files: {
                filesname: p1data.mobile + '.png',
              },
            },
            otp: resData.otp,
            name: p1data.mobile + '_AadhaarPhoto.jpeg',
            type: 'image/jpeg',
            uri: uri,
          });
        }
      });
    } catch (err) {
      // alert("error fetching sanction letter");
      console.log(err);
    }
  };
  const onSubmit = async event => {
    try {
      setloading(true);
      console.log('signzydata');
      p1data = {
        ...p1data,
        files: {
          filesname: p1data.mobile + '.png',
        },
      }
      console.log(signzydata);
      console.log('signzydataxxxx');
      console.log({
        idx: signzydata.data.result.requestId,
        userId: signzydata.data.patronId,
        Authorization: signzydata.Authorization,
        p1data: p1data
      });
      var datax = GES(
        {
          idx: signzydata.data.result.requestId,
          userId: signzydata.data.patronId,
          Authorization: signzydata.Authorization,
          p1data: p1data
        },
        apiEN,
      );
      console.log('datax');
      console.log(datax);
      await axios({
        url: `${process.env.REACT_APP_API_LINK}/signzyverification2/`,
        method: 'POST',
        responseType: 'json',
        data: datax, // important
      })
        .then(async response => {
          if (response.data === "KYC ALREADY DONE FOR THIS AADHAAR") {
            alert(`KYC ALREADY DONE FOR THIS AADHAAR `);
          }
          else if (response.data.text === "KYC FAILED") {
            alert(response.data.message);
          }
          else {
            let resData = GDSRecords(response.data, apiDE);
            console.log(resData['result']['photo']);
            p1data['aadhar_no'] = resData['result']['uid'];
            p1data['dob'] = new Date(convertToDate(resData['result'].dob))
              .toISOString()
              .slice(0, 19)
              .replace('T', ' ');
            p1data['kyccertificate'] = JSON.stringify(
              resData['result']['x509Data'],
            );
            // UploadFiles(resData['photo'], p1data);
            p1data['kycid'] = signzydata.data.result.requestId
            p1data['files'] = {
              filesname: p1data.mobile + '.png',
            }
            // getOTP(resData['result']['photo'], p1data);
            // console.log(p1data);



            try {
              setloading(true);
              var ProfileData = p1data;

              var datax = GES(
                {
                  name_as_pan: ProfileData.name_as_pan,
                  mobile: ProfileData.mobile,
                  aadhar_no: ProfileData.aadhar_no,
                  gender: ProfileData.gender,
                  pan_no: ProfileData.pan_no,
                  dob: ProfileData.dob,
                  employment: ProfileData.employment,
                  monthlyincome: ProfileData.monthlyincome,
                  address_line1: ProfileData.address_line1,
                  address_line2: ProfileData.address_line2,
                  lpg_dist_id: ProfileData.lpg_dist_id,
                  lpg_id: ProfileData.lpg_id,
                  state: ProfileData.state,
                  city: ProfileData.city,
                  pincode: ProfileData.pincode,
                  district: ProfileData.district,
                  kyccertificate: ProfileData.kyccertificate,
                  status: true,
                  files: JSON.stringify(ProfileData.files),
                  kyc: 'approved',
                  helperid: null,
                  kycid: ProfileData.kycid
                  ,
                  files: {
                    filesname: p1data.mobile + '.png',
                  },
                },
                apiEN);

              await axios({
                url: `${process.env.REACT_APP_API_LINK}/app_personal_details3`,
                method: 'POST',
                responseType: 'json',
                data: datax, // important
              })
                .then(response => {
                  console.log({
                    uri: resData['result']['photo'],
                    name: p1data.mobile + '.png',
                    type: 'image/jpeg',
                  });
                  const formDatax = new FormData();
                  formDatax.append('image', {
                    uri: resData['result']['photo'],
                    name: p1data.mobile + '.png',
                    type: 'image/jpeg',
                  });
                  console.log(datax);
                  console.log(p1data);
                  alert('Profile Procedure Successful for ' + ProfileData.name_as_pan);
                  navigate('/home');
                })
                .catch(err => {
                  alert('Error Creating Profile, Please try again');
                  console.log(err);
                });
            } catch (err) {
              console.log(err);
              console.log(err.response.statusText);
              if (err.response.statusText === 'Payload Too Large') {
                console.log({
                  text: 'Image size too large',
                  icon: 'error',
                });
              }
            }
            setloading(false);
          }

        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="pdivxyz">
      <h4 className='h4x'>DO NOT CLOSE THIS SCREEN</h4>
      <div className="buttoncont">
        <button type="button" className='buttonx' onClick={() => {
          console.log(p1data);
          const url = datax["url"]; // Replace with your desired URL
          window.open(url, "_blank");
        }}>OPEN KYC LINK</button>
        <button type="button" class="secondary" className='buttonx' onClick={() => { onSubmit() }}>Check KYC</button>
      </div></div>

  )
}

export default SignzyConfirmScreen