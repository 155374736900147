import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./KFS.scss";
import logo from "./assets/jainexlogo.png";

const axios = require("axios");

const KFS = () => {
  //VARS
  const [loading, setloading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  //FUNCTIONS
  const refilldata = location.state.refilldata;


  const Submit = async (event) => {
    // event.preventDefault();
    // alert(name + phone);
    try {
      navigate("/refill4", {
        state: { refilldata: refilldata },
      });
    } catch (err) {
      // alert("error fetching sanction letter");
      console.log(err);
    }
  };
  console.log(location);

  return (
    <div
      style={{
        display: "flex",
        alignSelf: "center",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "center",
        // size: "5rem",
      }}
    >
      {loading ? (
        <div style={{ alignSelf: "center" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="Refillscreen3">

          <div
            style={{
              padding: "5%",
              paddingTop: '0%',
              display: "flex",
              flexDirection: "column",
              fontFamily: "Arial, Helvetica, sans-serif",
            }}
          >
            {/* Header start */}
            <br />

            <div style={{ display: "flex", flexDirection: "row" }}>
              <h1 style={{ alignSelf: "center", flex: 4, fontSize: "1.5rem" }}>
                Jainex Finance Limited
              </h1>
              <img
                // width={50}
                height={80}

                src={logo}
                style={{
                  alignSelf: "center",

                }}
                alt=""
              />
            </div>
            <p
              style={{
                alignSelf: "center",
                textAlign: "center",
                fontSize: "0.8rem",
              }}
            >
              CIN : U65921AS1993PLC003858 Email : jainex@jainexfin.com Hand
              Phone: +91-9818093311 Suite No. 304-305, Rustomjee Central Park,
              Andheri Kurla Road, Andheri East, Mumbai - 400 093
            </p>

            {/* Header end */}

            <h1 style={{ alignSelf: "center" }}>KEY FACT STATEMENT</h1>
            <p
              style={{
                textAlign: "center",
                fontSize: "1rem",
              }}
            >
              (as per RBI notification
              no.RBI/422/DBR.Leg.No.BC.64/09.07.005/2014-15 dated 22.01.2015)
            </p>
            <br />
            {/* <p>
              Loan…<span className="s3"> </span>(Name of the Specific Loan
              Product)
            </p> */}
            <table style={{ margin: "1%", textAlign: "center" }}>
              <tbody>
                <tr>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">1</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">Loan Amount</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Rs. 600 (Six Hundred Only)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "33" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">2</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">Loan Term</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      45 Days (Maximum 60 Days)
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "32" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">3</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        paddingRight: "152",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      Interest Type (fixed or floating)
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      0% (Zero Percent) Interest Rate
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "58" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">4</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      Interest Chargeable (In Case of Floating Rate Loans)
                    </p>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      Interest Chargeabe (In Case of Fixed Rate Loans)
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br /> Not Applicable
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "32" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">5</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">Date of reset of interest</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br /> Not Applicable
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "31" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">6</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      Mode of communication of changes in interest rates
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Zero Interest Rate
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "27" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">7</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">Fee payable</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Zero Charges
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "32" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">a</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">On application</p>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      (PI individually specify all type of fee)
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Zero Charges
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "31" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">b</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">During the term of the loan</p>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      (PI individually specify all type of fee)
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Zero Charges
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "41" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">c</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">On foreclosure</p>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      (PI individually specify all type of fee)
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Zero Charges
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "28" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">d</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">
                      Fee refundable if loan not sanctioned/disbursed
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Not Applicable
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "45" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">e</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        paddingRight: "10",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      Conversion charges for switching from floating to fixed
                      interest and vice-versa
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Zero Charges
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "32" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">f</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">Penalty for delayed payments</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Rs.100/- from the 4th (fourth) day from the last due date
                      on which such payment fell due
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "31" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">8</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">EMI payable</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      Weekly/Monthly instalment is to be made as per EMI
                      Schedule of Sanction Letter as also indicated in the
                      Greattr Portal
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "32" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">9</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">Detail of security/Collateral obtained</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      LPG cylinder issued to customer under PMUY scheme Charges
                      on Default – LPG Cylinder shall be taken back by the
                      distributor of Bharat Petroleum Corporation Limited from
                      the customer
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "31" }}>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p className="s4">10</p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p
                      className="s4"
                      style={{
                        paddingLeft: "5",
                        textIndent: "0",
                        textAlign: "left",
                      }}
                    >
                      Date on which annual outstanding balance statement will be
                      issued.
                    </p>
                  </td>
                  <td style={{ border: "1px solid black", height: "10%" }}>
                    <p>
                      <br />
                      30 days from the last due date on which such payment fell
                      due
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              onClick={() => {
                Submit();
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default KFS;