import React, { useState, useEffect, useRef } from "react";
import "./UserProfileForm.scss";
import { IconContext } from "react-icons";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
import * as CgIcons from "react-icons/cg";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import { Button, Pagination, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { GES, GDS, GDSRecords } from "../ENDE";
import axios from "axios";
const apiEN = process.env.REACT_APP_GES1;
const apiDE = process.env.REACT_APP_GES3;

const UserRefillForm = () => {
  var datax = localStorage.getItem("userdata");
  datax = JSON.parse(datax);
  console.log(datax);
  const [loading, setloading] = useState(false);

  // Define state variables for each input field
  const [nameAsPan, setNameAsPan] = useState("");
  const [mobile, setMobile] = useState("");
  const [aadharNo, setAadharNo] = useState("");
  const [gender, setGender] = useState("");
  const [panNo, setPanNo] = useState("");
  const [dob, setDob] = useState("");
  const [employment, setEmployment] = useState("");
  const [monthlyIncome, setMonthlyIncome] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [lpgDistId, setLpgDistId] = useState("");
  const [lpgId, setLpgId] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [district, setDistrict] = useState("");
  const [year, setyear] = useState("");
  const [month, setmonth] = useState("");
  const [day, setday] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    try {
      setloading(true);
      const datax = JSON.parse(localStorage.getItem("userdata"));
      for (const key in datax) {
        if (datax.hasOwnProperty(key)) {
          if (datax[key] === "null" || datax[key] === "undefined") {
            datax[key] = null;
          }
        }
      }
      if (datax) {
        setNameAsPan(datax.name_as_pan);
        setMobile(datax.mobile);
        setAadharNo(datax.aadhar_no);
        setGender(datax.gender);
        setPanNo(datax.pan_no);
        setDob(datax.dob);
        setEmployment(datax.employment);
        setMonthlyIncome(datax.monthlyincome);
        setAddressLine1(datax.address_line1);
        setAddressLine2(datax.address_line2);
        setLpgDistId(datax.lpg_dist_id);
        setLpgId(datax.lpg_id);
        setState(datax.state);
        setCity(datax.city);
        setPincode(datax.pincode);
        setDistrict(datax.district);
      }
      setloading(false);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a formData object with the state values
    const formData = {
      personalDetails: {
        name_as_pan: nameAsPan,
        name: nameAsPan,
        mobile: mobile,
        aadhar_no: aadharNo,
        gender: gender,
        pan_no: panNo,
        dob: "" + year + "-" + month + "-" + day,
        employment: employment,
        lpg_mobile: mobile,
        monthlyincome: monthlyIncome,

      },
      addressDetails: {
        address_line1: addressLine1,
        address_line2: addressLine2,
        lpg_dist_id: lpgDistId,
        lpg_id: lpgId,
        state: state,
        city: city,
        pincode: pincode,
        district: district,
        address_line1: addressLine1,
        address_line2: addressLine2,
        city: city,
        state: state,
        pincode: pincode,
        district: district,
        address: addressLine1 + "" + addressLine2,
        amount: 600,
        user_approved: "approved",
      },
      // Add otherDetails and other fields if needed
    };

    // Send formData to your backend or perform any other actions here
    console.log(formData);

    // Validation checks
    const errors = {};

    // Personal Details Validation
    if (!formData.personalDetails.name_as_pan) {
      errors.name_as_pan = "Name as PAN is required.";
    }

    if (!formData.personalDetails.mobile || formData.personalDetails.mobile.length !== 10) {
      errors.mobile = "Mobile number is required.";
    } else if (!/^\d+$/.test(formData.personalDetails.mobile)) {
      errors.mobile = "Mobile number must contain only digits.";
    }

    if (!formData.personalDetails.aadhar_no) {
      errors.aadhar_no = "Aadhar number is required.";
    } else if (!formData.personalDetails.aadhar_no.length === 12) {
      console.log(formData.personalDetails.aadhar_no);
      console.log(formData.personalDetails.aadhar_no.length === 12);
      errors.aadhar_no = "Aadhar number must be 12 digits long.";
    }

    if (!formData.personalDetails.gender) {
      errors.gender = "Gender is required.";
    }

    if (!formData.personalDetails.pan_no) {
      errors.pan_no = "PAN number is required.";
    } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.personalDetails.pan_no)) {
      errors.pan_no = "Invalid PAN format. Example: ABCDE1234F";
    }

    // Validate Date of Birth
    if (!formData.personalDetails.dob) {
      errors.dob = "Date of Birth is required.";
    } else {
      const dobParts = formData.personalDetails.dob.split("-");
      if (dobParts.length !== 3) {
        errors.dob = "Invalid Date of Birth format. Use YYYY-MM-DD.";
      } else {
        const year = parseInt(dobParts[0], 10);
        const month = parseInt(dobParts[1], 10);
        const day = parseInt(dobParts[2], 10);

        if (isNaN(year) || isNaN(month) || isNaN(day)) {
          errors.dob = "Invalid Date of Birth format. Use numbers for year, month, and day.";
        } else {
          const dobDate = new Date(year, month - 1, day); // Month is 0-based
          const currentDate = new Date();
          const minAgeDate = new Date(currentDate);
          minAgeDate.setFullYear(currentDate.getFullYear() - 18);

          if (dobDate > currentDate) {
            errors.dob = "Date of Birth cannot be in the future.";
          } else if (dobDate > minAgeDate) {
            errors.dob = "You must be 18 years or older.";
          }
        }
      }
    }


    if (!formData.personalDetails.employment) {
      errors.employment = "Employment status is required.";
    }

    if (!formData.personalDetails.monthlyincome) {
      errors.monthlyincome = "Monthly income is required.";
    }

    // Address Details Validation
    if (!formData.addressDetails.address_line1) {
      errors.address_line1 = "Address Line 1 is required.";
    }

    if (!formData.addressDetails.state) {
      errors.state = "State is required.";
    }

    if (!formData.addressDetails.city) {
      errors.city = "City is required.";
    }

    if (!formData.addressDetails.pincode) {
      errors.pincode = "Pincode is required.";
    } else if (!/^\d{6}$/.test(formData.addressDetails.pincode)) {
      errors.pincode = "Invalid Pincode. Must be 6 digits.";
    }

    if (!formData.addressDetails.district) {
      errors.district = "District is required.";
    }

    // Check if there are any errors
    if (Object.keys(errors).length > 0) {
      // Handle errors, display them to the user, or prevent form submission
      console.log("Validation errors:", errors);
      const errorMessages = Object.values(errors).join('\n');
      alert("Validation errors:\n" + errorMessages);
    } else {
      // No validation errors, proceed with form submission or other actions
      console.log("No validation errors. Form data:", formData);
      console.log("ithe bc");
      var r2data = {

        ...formData.addressDetails,
        ...formData.personalDetails,

      }
      localStorage.setItem("refilldata", JSON.stringify(r2data));

      navigate('/KFS', {
        state: { refilldata: r2data },
      });


    }
  };
  const monthlyIncomeOptions = [
    { value: "less than 10000" },
    { value: "10000 to 25000" },
    { value: "more than 25000" },
  ];

  const Pinchange = async e => {
    const pin = e.target.value;
    setPincode(e.target.value);
    setTimeout(() => {
      setloading(false);
    }, 5000);
    if (pin.length === 6) {
      try {
        setloading(true);
        console.log(pin);
        await axios({
          url: `https://api.postalpincode.in/pincode/` + pin,
          method: 'GET',
          responseType: 'json', // important
        }).then(response => {
          console.log(response);
          // console.log(response.data[0].PostOffice[0]['State']);
          setState(response.data[0].PostOffice[0]['State']);
          setCity(response.data[0].PostOffice[0]['Region']);
          setloading(false);
          setDistrict(response.data[0].PostOffice[0]['District']);
        });
      } catch (err) {
        alert(("InvalidPincode"));
        setloading(false);
        console.log(err);
      } finally {
        setloading(false);
      }
    }
  };
  const generateYearOptions = () => {
    const arr = [];

    const startYear = 1900;
    const endYear = new Date().getFullYear();

    for (let i = endYear; i >= startYear; i--) {
      arr.push(<option value={i}>{i}</option>);
    }

    return arr;
  };
  const generateMonthOptions = () => {
    const arr = [];

    const startYear = 1;
    const endYear = 12;

    for (let i = endYear; i >= startYear; i--) {
      i < 10
        ? arr.push(<option value={"0" + i}>{i}</option>)
        : arr.push(<option value={i}>{i}</option>);
    }

    return arr;
  };
  const generateDayOptions = () => {
    const arr = [];

    const startYear = 1;
    const endYear = 31;
    for (let i = endYear; i >= startYear; i--) {
      arr.push(<option value={i}>{i}</option>);
    }

    return arr;
  };
  if (loading) {
    return <h3>LOADING</h3>;
  }
  return (
    <div className="user-profile-form">
      <h1 style={{ color: '#6c5ce7' }}>Refill Profile Form</h1>
      <form onSubmit={handleSubmit}>
        <h3 style={{ color: '#6c5ce7' }}>Personal Details</h3>
        <div class="data-display">
          <div className="form-section">
            <div className="form-group">
              <div>
                <label>NAME AS AADHAAR</label>

                <input
                  type="text"
                  onChange={(e) => setNameAsPan(e.target.value)}
                  value={nameAsPan}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>MOBILE</label>

                <input
                  type="text"
                  onChange={(e) => setMobile(e.target.value)}
                  value={mobile}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>LPG DIST ID</label>

                <input
                  type="text"
                  disabled={true}
                  // onChange={(e) => setLpgDistId(e.target.value)}
                  value={lpgDistId}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>LPG ID</label>

                <input
                  type="text"
                  disabled={true}
                  // onChange={(e) => setLpgId(e.target.value)}
                  value={lpgId}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>AADHAAR NO(LAST 4 DIGITS)</label>

                <input
                  type="text"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    // Remove any non-digit characters
                    const numericValue = inputValue.replace(/\D/g, '');
                    // Ensure it's not longer than 4 digits
                    const truncatedValue = numericValue.slice(0, 4);
                    // Add a prefix of 'xxxxxxxx' followed by the truncated value
                    const formattedValue = 'xxxxxxxx' + truncatedValue;

                    setAadharNo(formattedValue);
                  }}
                  value={aadharNo}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>GENDER</label>
                <select onChange={(e) => setGender(e.target.value)} value={gender}>
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>PAN NO</label>

                <input
                  type="text"
                  onChange={(e) => setPanNo(e.target.value)}
                  value={panNo}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>Date Of Birth (As per PAN) :</label>
                <div className="DOB">
                  <select
                    name="day"
                    onChange={(e) => {
                      setday(e.target.value);
                    }}
                    value={day}
                  >
                    <option value="0">Day</option>
                    {generateDayOptions()}
                  </select>

                  <select
                    name="month"
                    onChange={(e) => {
                      setmonth(e.target.value);
                    }}
                    value={month}
                  >
                    <option value="0">Month</option>
                    {generateMonthOptions()}
                  </select>
                  <select
                    name="year"
                    onChange={(e) => setyear(e.target.value)}
                    value={year}
                  >
                    <option value="0">Year</option>
                    {generateYearOptions()}
                  </select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>EMPLOYMENT</label>

                <input
                  type="text"
                  onChange={(e) => setEmployment(e.target.value)}
                  value={employment}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>MONTHLY INCOME</label>
                <select onChange={(e) => setMonthlyIncome(e.target.value)} value={monthlyIncome}>
                  <option value="">Select Monthly Income</option>
                  {monthlyIncomeOptions.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <h3 style={{ color: '#6c5ce7', paddingTop: 20 }}>Address Details</h3>
        <div class="data-display">
          <div className="form-section">
            <div className="form-group">
              <div>
                <label>ADDRESS LINE1</label>

                <input
                  type="text"
                  onChange={(e) => setAddressLine1(e.target.value)}
                  value={addressLine1}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>ADDRESS LINE2</label>

                <input
                  type="text"
                  onChange={(e) => setAddressLine2(e.target.value)}
                  value={addressLine2}
                />
              </div>
            </div>

            <div className="form-group">
              <div>
                <label>STATE</label>

                <input
                  type="text"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>CITY</label>

                <input
                  type="text"
                  onChange={(e) => setCity(e.target.value)}
                  value={city}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>PINCODE</label>

                <input
                  type="text"
                  onChange={(e) => Pinchange(e)}
                  value={pincode}
                />
              </div>
            </div>
            <div className="form-group">
              <div>
                <label>DISTRICT</label>

                <input
                  type="text"
                  onChange={(e) => setDistrict(e.target.value)}
                  value={district}
                />
              </div>
            </div>
          </div>
        </div>
        <Button variant="primary fw-bold mx-1 my-2 " type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default UserRefillForm;
